
import { mapGetters } from 'vuex'
export default {
    name: 'GameMenu',
    data() {
        return {
            gameMenuHeight: 200,
            gameList: [],
            gameIndex: 0,
            isScrollDown: false,
            menuBlockType: 1, // 1 is a 2-3-4 Images on a row Grid Style, 2 is one image for one row
        }
    },
    computed: {
        ...mapGetters({
            menubars: 'cms/menubars',
        }),
        blockType() {
            return this.menuBlockType
        },
    },
    watch: {
        menubars(value) {
            if (value) {
                if (value.length > 0) {
                    this.onChangeGameMenu(this.gameIndex)
                }
                const games = this.getVisibleItems(value)

                if (games.length > 0) {
                    const items = games[0].items.map((data) => {
                        return {
                            ...data,
                            component_code: games[0].component_code,
                        }
                    })
                    this.gameList = items
                }
            }
        },
    },
    mounted() {
        this.onResize()
        this.setOriginalMenu()
        if (this.menubars && this.menubars.length > 0) {
            this.onChangeGameMenu(this.gameIndex)
        }
        const element = document.getElementById('home-slider-theme3')
        function getElementPosition() {
            const rect = element.getBoundingClientRect()
            return rect.top + window.scrollY
        }
        const vm = this
        window.addEventListener('scroll', function () {
            const position = getElementPosition()
            if (position > 230) {
                vm.isScrollDown = true
            } else {
                vm.isScrollDown = false
            }
        })
    },
    methods: {
        onResize() {
            if (process.browser) {
                const WINDOW_WIDTH = window.innerWidth
                let IMAGE_SLIDER_HEIGHT = 298
                if (WINDOW_WIDTH < 960) {
                    IMAGE_SLIDER_HEIGHT = (WINDOW_WIDTH * 450) / 1320
                }

                const HEADER_HEIGHT = 82
                const ANNOUNCEMENT_HEIGHT = 36
                const SLIDER_PADDING = 12 * 2
                const CUSTOM_FOOTER_HEIGHT = 80

                const REST_OF_GAME_HEIGHT =
                    ~~HEADER_HEIGHT +
                    ~~ANNOUNCEMENT_HEIGHT +
                    ~~SLIDER_PADDING +
                    ~~IMAGE_SLIDER_HEIGHT +
                    ~~CUSTOM_FOOTER_HEIGHT

                this.gameMenuHeight = window.innerHeight - REST_OF_GAME_HEIGHT
            }
        },
        setOriginalMenu() {
            // if you play game, and come back to the page, the active menu games will be active
            const componentId = this.$cookie.get('component')
            let menubarIndex = 0
            if (componentId) {
                let menuIndex = 0
                for (const menubar of this.menubars) {
                    if (menubar._id === componentId) {
                        menubarIndex = menuIndex
                        break
                    }
                    menuIndex = menuIndex + 1
                }
            } else {
                menubarIndex = null
            }
            this.gameIndex = menubarIndex
        },
        onChangeGameMenu(id) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            let index = id
            if (id === null) {
                index = 0
            }
            this.gameIndex = index
            const items = this.getVisibleItems(this.menubars)[
                index
            ]?.items?.map((data) => {
                return {
                    ...data,
                    component_code: this.getVisibleItems(this.menubars)[index]
                        .component_code,
                }
            })
            this.menuBlockType = this.getVisibleItems(this.menubars)[
                index
            ]?.block_type

            this.gameList = items
        },
        getImageUri(icon) {
            if (!icon) return null
            const menubarIcon = ~~icon
            let imagePath = 'theme-3/mobile/games/v2/'
            // menubar_icon
            // 5 = slot
            // 3 = live casino
            // 8 = Virtual Sport
            // 1 = animal
            // 2 = finishing
            // 4 = Lottery
            // 6 = Sport
            // 7 = Table
            // 9 = Others
            if (menubarIcon === 1) imagePath += 'animal.png'
            else if (menubarIcon === 2) imagePath += 'fishing.png'
            else if (menubarIcon === 3) imagePath += 'casino.png'
            else if (menubarIcon === 4) imagePath += 'lottery.png'
            else if (menubarIcon === 5) imagePath += 'slot.png'
            else if (menubarIcon === 6) imagePath += 'sport.png'
            else if (menubarIcon === 7) imagePath += 'table-games.png'
            else if (menubarIcon === 8) imagePath += 'virtual.png'
            else if (menubarIcon === 9) imagePath += 'other-games.png'

            return imagePath
        },
        doPlayGame(item) {
            this.$cookie.set('component', item.component_content_id)
            this.openNewBrowserTab(this.getLocaleLang(item.link) || '', item)
        },
    },
}
