export default {
	head: {
		meta: [
			{
				hid: 'og:url',
				name: 'og:url',
				content: `https://www.${process.env.DOMAIN}`,
			},
		],
		link: [
			{
				rel: 'canonical',
				href: `https://www.${process.env.DOMAIN}`,
			},
		],
	},
}